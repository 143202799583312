import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  fontFamily,
  fontWeight,
  textAlign,
  lineHeight,
  letterSpacing,
} from 'styled-system';
import { base, themed } from '../base';
import { ListItem } from './textList.style';

const TEXT_LIST_STYLES = {
  paddingLeft: '20px',
  marginBottom: '1rem',
};

const TextListWrapper = styled('ul')(
  base,
  fontFamily,
  fontWeight,
  textAlign,
  lineHeight,
  letterSpacing,
  TEXT_LIST_STYLES,
  themed('Text')
);

const TextList = ({ list, ...props }) => (
  <TextListWrapper {...props}>
    {list.map(item => (
      <ListItem>{item}</ListItem>
    ))}
  </TextListWrapper>
);

TextList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.string),
};

export default TextList;
