import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  fontFamily,
  fontWeight,
  textAlign,
  lineHeight,
  letterSpacing,
} from 'styled-system';
import { base, themed } from '../base';
import { ListItem } from './textListLink.style';

const TEXT_LIST_STYLES = {
  paddingLeft: '20px',
  marginBottom: '1rem',
};

const TextListWrapper = styled('ul')(
  base,
  fontFamily,
  fontWeight,
  textAlign,
  lineHeight,
  letterSpacing,
  TEXT_LIST_STYLES,
  themed('Text')
);

const TextListLink = ({ list, ...props }) => (
  <TextListWrapper {...props}>
    {list.map(item => (
      <ListItem>
        <span>{item.text}</span>
        <br />
        <a href={item.link} target="_blank" rel="noreferrer">
          {item.link}
        </a>
      </ListItem>
    ))}
  </TextListWrapper>
);

TextListLink.propTypes = {
  list: PropTypes.array,
};

export default TextListLink;
