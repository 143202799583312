import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
// Containers
import Container from 'common/src/components/UI/Container';
// Components
import Heading from 'common/src/components/Heading';
import TextList from 'common/src/components/TextList';
import TextListLink from 'common/src/components/TextListLink';
import Text from 'common/src/components/Text';
// Styles
import SectionWrapper, {
  InnerWrapper,
  SectionHeader,
} from './cookieStatement.style';

const CookieStatement = () => {
  const data = useStaticQuery(graphql`
    query {
      appModernJson {
        cookieStatement {
          slogan
          articles {
            id
            title
            contents {
              paragraph
              list
              list_link {
                text
                link
              }
            }
          }
        }
      }
    }
  `);
  const { slogan, articles } = data.appModernJson.cookieStatement;

  return (
    <SectionWrapper id="cookieStatement">
      <Container>
        <SectionHeader>
          <Heading content={slogan} />
        </SectionHeader>

        <InnerWrapper>
          {articles.map(article => (
            <div key={article.id}>
              <Heading as="h3" mt="1.6rem" content={article.title} />
              {article.contents.map(content => (
                <>
                  {content.paragraph && <Text content={content.paragraph} />}
                  {content.list && <TextList list={content.list} />}
                  {content.list_link && (
                    <TextListLink list={content.list_link} />
                  )}
                </>
              ))}
            </div>
          ))}
        </InnerWrapper>
      </Container>
    </SectionWrapper>
  );
};

export default CookieStatement;
